<template>
  <v-container fill-height fluid class="gray lighten-4">
    <v-layout justify-center class="mt-15">
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12" max-width="400" min-width="250">
          <v-card-title class="cyan lighten-3 black--text mt-8 d-flex justify-center">
            <p class="ml-3">
              YodRonaldo
            </p>
          </v-card-title>

          <v-card-text class="cyan lighten-4 py-4 white">
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <CodeInput
                  :loading="false"
                  :autoFocus="true"
                  :fields="4"
                  class="input"
                  v-on:change="onChange"
                  v-on:complete="onComplete"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center cyan lighten-3"
            ><v-btn color="deep-orange darken-2" class="font-weight-black" text @click="OnLogoff">
              ออกเข้ารหัสอื่น
              <v-icon class="ml-3">mdi-location-exit</v-icon></v-btn
            ></v-card-actions
          >
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CodeInput from "vue-verification-code-input";
export default {
  name: "Pincode",
  components: {
    CodeInput,
  },
  methods: {
    onChange(v) {
      console.log("onChange ", v);
    },
    onComplete(v) {
      console.log("onComplete ", v);
      this.do_verify(v);
    },
    async do_verify(p_code) {
      let parameter = {
        pin_code: p_code,
      };

      this.$store.dispatch("auth/verify_pincode", parameter).then(
        (response) => {
        //   if (response == "Success") {

            this.$router.push("/Dashboard");
        //   }
          //console.log(response);
        },
        (error) => {
          // count in database
          localStorage.removeItem("blog_token");
          alert(error);
          //   this.login_message(error);
        }
      );
    },
    OnLogoff(){
        this.$confirm("","กดยืนยันเพื่อออกจากระบบ","question")
        .then(() => {
          localStorage.removeItem("blog_token");
          sessionStorage.removeItem("UserID");
          sessionStorage.removeItem("Nickname");
          this.$router.push("/Login");
        })
        .catch(() => {});
    },
  },
  data: () => ({
    code: "",
  }),
};
</script>

<style></style>
